import img1 from "../images/common/img-1.jpg";
import img2 from "../images/common/img-2.jpg";
import img3 from "../images/common/img-3.jpg";
import img4 from "../images/common/img-4.jpg";
import img5 from "../images/common/img-5.jpg";
import img6 from "../images/common/img-6.jpg";
import img7 from "../images/common/img-7.jpg";
import img9 from "../images/common/img-9.jpg";
import img10 from "../images/common/img-10.jpg";
import img11 from "../images/common/img-11.jpg";
import img12 from "../images/common/img-12.jpg";
import img13 from "../images/common/img-13.jpg";
// import img14 from "../images/common/img-14.jpg";
import img15 from "../images/common/img-15.jpg";
import img16 from "../images/common/img-16.jpg";

const dataProject = [
  {
    id: 1,
    title: "BASEDOGEAI#01",
    img: img1,
  },
  {
    id: 2,
    title: "BASEDOGEAI#02",
    img: img2,
  },
  {
    id: 3,
    title: "BASEDOGEAI#03",
    img: img3,
  },
  {
    id: 4,
    title: "BASEDOGEAI#04",
    img: img4,
  },
  {
    id: 5,
    title: "BASEDOGEAI#05",
    img: img5,
  },
  {
    id: 6,
    title: "BASEDOGEAI#06",
    img: img6,
  },
  {
    id: 7,
    title: "BASEDOGEAI#07",
    img: img7,
  },
  {
    id: 8,
    title: "BASEDOGEAI#08",
    img: img2,
  },

  {
    id: 9,
    title: "BASEDOGEAI#09",
    img: img9,
  },
  {
    id: 10,
    title: "BASEDOGEAI#10",
    img: img10,
  },
  {
    id: 11,
    title: "BASEDOGEAI#11",
    img: img11,
  },
  {
    id: 12,
    title: "BASEDOGEAI#12",
    img: img12,
  },
];

export default dataProject;
