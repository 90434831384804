import bgImg from "../images/background/bg.png";
import sliderImg from "../images/common/itemslider.png";

const dataSlider = {
  id: 1,
  title: "",
  desc: "Our bot offers a seamless and secure way to create Ethereum wallets on the Ethereum blockchain. Whether you're new to the world of cryptocurrencies or a seasoned investor, our user-friendly interface will guide you through the process effortlessly.",
  bgImg: bgImg,
  img: sliderImg,
  isSpecial: true,
};

export default dataSlider;
