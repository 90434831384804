import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import WorkItem from "./work-item";

Work.propTypes = {
  data: PropTypes.array,
};

function Work(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "How we work",
    title: "BECOME OUR MEMBER",
    desc: "Use TronAI to create unique NFTs and be part of the Arbitrum community",
  });
  return <section className="tf-section how-we-work" id="work"></section>;
}

export default Work;
