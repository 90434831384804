const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "About",
    links: "#about",
  },
];

export default menus;
