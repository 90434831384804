import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import RoadMapItem from "./roadmap-item";

RoadMap.propTypes = {
  data: PropTypes.array,
};

function RoadMap(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "ROADMAP",
    title: "TRONAI TIMELINE",
  });

  return <section className="tf-section tf-roadmap" id="roadmap"></section>;
}

export default RoadMap;
