const dataAbout = [
  {
    id: 1,
    title: "Wallet Generation",
    desc: "Generate a new Ethereum wallet with a unique address and private key, ensuring maximum security for your digital assets.",
  },
  {
    id: 2,
    title: "Balance Inquiry",
    desc: "Check your Ethereum wallet balance instantly to keep track of your holdings and transactions",
  },
  {
    id: 3,
    title: "Password Protection",
    desc: "Safeguard your wallet with a strong password, providing an additional layer of protection against unauthorized access.",
  },
];

export default dataAbout;
