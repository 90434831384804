import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import { Scrollbar, A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import BlogItem from "./blog-item";
import Button from "../../components/button";

Blog.propTypes = {
  data: PropTypes.array,
};

function Blog(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subtitle: "Speciality",
    title: "MORE STYLES",
  });

  return <section className="tf-section blog"></section>;
}

export default Blog;
